<template>
  <!-- banner -->
  <div class="section">
      <ToolTip style="margin-left: 20px;margin-top: 20px; position: absolute;z-index: 2;"
        :type="'top'"
        :msg="'This space is reserved for a personalized team banner photo! It will be represented on your company page (viewable by visitors to your page) as well as on your home and work pages (viewable by your team and those you invite to the specific work pages).<br><br>To change banner photo please head over to your company profile page, there you will have the option to post your photo and better represent your organization!<br><br>Only company administrators can edit the team’s banner photo so if you can’t make the edit<br><br>- Request from your administrator to add it<br><br>- Request to be added as an administrator for your team<br><br>- If you are an administrator and it still is not working please reach out to our team so that we can be aware of your issue and solve it as soon as possible.'"
      />

      <div class="section__content p_0">
          <div v-if="type == 'edit'" class="section__banner"
            v-bind:style="{ backgroundImage: 'url(' + userdata.user_profile.company.companybanner?.link + ')' }"
                >
            <div v-if="type == 'edit'" class="banner__settings banner-settings">
              <a href="#" @click.prevent="editCompanyBanner" class="user-settings__link">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="4" fill="#00A961"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.40901 9.90901C8.83097 9.48705 9.40326 9.25 10 9.25H15.25C15.6642 9.25 16 9.58579 16 10C16 10.4142 15.6642 10.75 15.25 10.75H10C9.80109 10.75 9.61032 10.829 9.46967 10.9697C9.32902 11.1103 9.25 11.3011 9.25 11.5V22C9.25 22.1989 9.32902 22.3897 9.46967 22.5303C9.61032 22.671 9.80109 22.75 10 22.75H20.5C20.6989 22.75 20.8897 22.671 21.0303 22.5303C21.171 22.3897 21.25 22.1989 21.25 22V16.75C21.25 16.3358 21.5858 16 22 16C22.4142 16 22.75 16.3358 22.75 16.75V22C22.75 22.5967 22.5129 23.169 22.091 23.591C21.669 24.0129 21.0967 24.25 20.5 24.25H10C9.40326 24.25 8.83097 24.0129 8.40901 23.591C7.98705 23.169 7.75 22.5967 7.75 22V11.5C7.75 10.9033 7.98705 10.331 8.40901 9.90901Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.15918C21.777 9.15918 21.5631 9.24778 21.4053 9.4055L14.4272 16.3836L14.0308 17.9694L15.6166 17.5729L22.5947 10.5948C22.7524 10.4371 22.841 10.2232 22.841 10.0002C22.841 9.77713 22.7524 9.56322 22.5947 9.4055C22.437 9.24778 22.2231 9.15918 22 9.15918ZM20.3447 8.34484C20.7837 7.90582 21.3791 7.65918 22 7.65918C22.6209 7.65918 23.2163 7.90582 23.6553 8.34484C24.0944 8.78386 24.341 9.3793 24.341 10.0002C24.341 10.621 24.0944 11.2165 23.6553 11.6555L16.5303 18.7805C16.4342 18.8766 16.3138 18.9448 16.1819 18.9778L13.1819 19.7278C12.9263 19.7917 12.656 19.7168 12.4697 19.5305C12.2834 19.3442 12.2085 19.0738 12.2724 18.8183L13.0224 15.8183C13.0554 15.6864 13.1236 15.566 13.2197 15.4698L20.3447 8.34484Z" fill="white"/>
                </svg>
              </a>
            </div>
          </div>
          <div v-else class="section__banner"
            v-bind:style="{ backgroundImage: 'url(' + (company.view_company_profile.companybanner ? company.view_company_profile.companybanner.link : '') + ')' }"
                >
            <div class="banner__settings banner-settings">
              <div class="col col_12 col_big-desktop-12">
                  <div class="btn-group content_desktop-right ml_desktop-auto w-auto">
                      <a href="#" @click.prevent="sendMSG" class="btn btn_tertiary">
                          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V13.9393L4.71967 12.2197C4.86032 12.079 5.05109 12 5.25 12H14.25C14.4489 12 14.6397 11.921 14.7803 11.7803C14.921 11.6397 15 11.4489 15 11.25V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H3.75ZM2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V11.25C16.5 11.8467 16.2629 12.419 15.841 12.841C15.419 13.2629 14.8467 13.5 14.25 13.5H5.56066L2.78033 16.2803C2.56583 16.4948 2.24324 16.559 1.96299 16.4429C1.68273 16.3268 1.5 16.0533 1.5 15.75V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901Z" fill="#00A961"></path>
                          </svg>
                          <span class="btn__text">Send message</span>
                      </a>
                      <a href="#" @click.prevent="stop" @click="changeCompanyLike" class="btn btn_tertiary like" :class="company.view_company_profile.like_active ? 'active' : ''" role="button">
                          <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7125 1.49902C12.0721 1.49902 11.438 1.62519 10.8464 1.87033C10.2548 2.11544 9.7172 2.47482 9.26451 2.92769L8.99997 3.19223L8.7353 2.92756C7.82088 2.01314 6.58065 1.49942 5.28747 1.49942C3.99428 1.49942 2.75406 2.01314 1.83964 2.92756C0.925216 3.84198 0.411499 5.08221 0.411499 6.37539C0.411499 7.66858 0.925216 8.9088 1.83964 9.82322L8.46964 16.4532C8.76253 16.7461 9.2374 16.7461 9.5303 16.4532L16.1603 9.82322C16.6132 9.37053 16.9724 8.83306 17.2175 8.2415C17.4627 7.64989 17.5888 7.01578 17.5888 6.37539C17.5888 5.735 17.4627 5.10089 17.2175 4.50928C16.9724 3.91778 16.613 3.3801 16.1602 2.92744C15.7075 2.47463 15.1701 2.11542 14.5786 1.87033C13.987 1.62519 13.3529 1.49902 12.7125 1.49902Z" fill="#00A961"></path>
                          </svg>
                          <span class="btn__text">{{ company.view_company_profile.like_active ? 'Liked' : 'Like' }} ({{company.view_company_profile.like}})</span>
                      </a>
                  </div>
              </div>
            </div>
          </div>
      </div>
      <div class="section__content comp-info">
          <div class="row">
            <!-- Stage of funding entrepreneur -->
              <div v-if="company.companyInfo.companytype == 'startup' && type == 'edit'"
                class="col col_3 col_big-desktop-3 col_mob-12">
                  <div class="form-field mb_mobile-0">
                      <div class="form-field__content">

                        <div :class="{'dropdown_active': selectSoF}" class="dropdown" v-if="company.companyInfo.companytype == 'startup'">
                          <div @click.prevent="openDropDown('selectSoF')" class="dropdown__init btn btn_select">
                            <span class="title-text">
                                {{ company.regCompanyFunding.filter(a => a.selected).map(a => a.value)[0] ?
                                  company.regCompanyFunding.filter(a => a.selected).map(a => a.value)[0] :
                                  'Stage of funding'}}
                            </span>
                            <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                            </svg>
                          </div>
                          <div class="dropdown__content">
                            <ul class="dropdown__list">
                              <li v-for="funding in company.regCompanyFunding" :key="funding.id" class="dropdown__item">
                                <div class="dropdown__link pointer"
                                  :class="{'jcf-selected': funding.selected}"
                                  @click.prevent="selectStageOfFunding(funding.id)">
                                    <span>{{funding.value}}</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                  </div><!-- //form-field -->
              </div>
              <div v-if="company.view_company_profile.companytype == 'startup' && type == 'view'"
                class="col col_3 col_big-desktop-3 col_mob-12">
                <div class="form-field mb_mobile-0">
                  <div class="form-field__content">
                    <div class="comp-prof-banner-txt">
                      <p><strong>Stage of funding</strong></p>
                      <ul class="comp-view-list"><li>{{company.view_company_profile.stage_of_funding ? company.view_company_profile.stage_of_funding : 'Preparing for funding'}}</li></ul>
                    </div>
                  </div>
                </div><!-- //form-field -->
              </div>

              <!-- Type of Investments investor -->
              <div v-if="company.companyInfo.companytype == 'investment' && type == 'edit'"
                class="col col_3 col_big-desktop-3 col_mob-12">
                  <div class="form-field mb_mobile-0">
                      <div class="form-field__content">

                        <div :class="{'dropdown_active': selectToI}" class="dropdown" v-if="company.companyInfo.companytype == 'investment'">
                          <div @click.prevent="openDropDown('selectToI')" class="dropdown__init btn btn_select">
                            <span class="title-text">
                                {{ company.companyInfo.investment ? company.companyInfo.investment : 'Type of Investments'}}
                            </span>
                            <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                            </svg>
                          </div>
                          <div class="dropdown__content">
                            <ul class="dropdown__list">
                              <li v-for="industry in company.regCompanyInvestment" :key="industry.id" class="dropdown__item">
                                <div class="dropdown__link pointer"
                                  :class="{'jcf-selected': industry.selected}"
                                  @click.prevent="selectTypeOfInvestments(industry.id)">
                                    <span>{{industry.value}}</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                  </div><!-- //form-field -->
              </div>
              <div v-if="company.view_company_profile.companytype == 'investment' && type == 'view'"
                class="col col_3 col_big-desktop-3 col_mob-12">
                  <div class="form-field mb_mobile-0">
                      <div class="form-field__content">
                        <div class="comp-prof-banner-txt">
                          <p><strong>Type of Investments</strong></p>
                          <ul class="comp-view-list"><li>{{company.view_company_profile.investment}}</li></ul>
                        </div>
                      </div>
                  </div><!-- //form-field -->
              </div>

              <!-- Industries -->
              <div class="col col_6 col_big-desktop-6 col_mob-12">
                  <div class="form-field mb_mobile-0">
                      <div class="form-field__content">
                        <!-- entrepreneur -->
                          <div v-if="type == 'edit'">

                          <div :class="{'dropdown_active': selectIndustry}" class="dropdown" v-if="company.companyInfo.companytype == 'startup'">
                            <div @click.prevent="openDropDown('selectIndustry')" class="dropdown__init btn btn_select">
                              <span class="title-text">
                                {{ company.regCompanyIndustry.filter(a => a.selected).length ?
                                  company.regCompanyIndustry.filter(a => a.selected).map(a => a.value).join(', ') :
                                  'Field of Expertise' }}
                                  <!-- Please select ... -->
                              </span>
                              <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                              </svg>
                            </div>
                            <div class="dropdown__content">
                              <ul class="dropdown__list three-rows">
                                <li v-for="(industry, index) in company.regCompanyIndustry" :key="industry.id" class="dropdown__item">
                                  <div class="custom-check dropdown__link">
                                    <label class="custom-check__label">
                                      <input class="custom-check__input"
                                        v-model="industry.selected"
                                        @change="inputChangeIndustry(index)"
                                        type="checkbox" name="chekbox">
                                      <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                          <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                        </g>
                                      </svg>
                                      <span class="custom-check__text limit_width">{{industry.value}}</span>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>


                          </div>

                          <div class="comp-prof-banner-txt" v-if="company.view_company_profile.companytype == 'startup' && type == 'view'">
                            <p><strong>Industry</strong></p>
                            <ul class="comp-view-list">
                              <li v-for="industry in company.view_company_profile.companyindustry.slice(0, 4)" :key="industry.id">{{industry.value}}</li>
                              <li v-if="company.view_company_profile.companyindustry.length > 4">+{{company.view_company_profile.companyindustry.length-4}}</li>
                            </ul>
                          </div>

                          <!-- expert -->
                          <div v-if="type == 'edit'">

                          <div :class="{'dropdown_active': selectIndustry}" class="dropdown" v-if="company.companyInfo.companytype == 'business'">
                            <div @click.prevent="openDropDown('selectIndustry')" class="dropdown__init btn btn_select">
                              <span class="title-text">
                                {{ company.regCompanyIndustry.filter(a => a.selected).length ?
                                  company.regCompanyIndustry.filter(a => a.selected).map(a => a.value).join(', ') :
                                  'Field of Expertise' }}
                                  <!-- Please select ... -->
                              </span>
                              <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                              </svg>
                            </div>
                            <div class="dropdown__content">
                              <ul class="dropdown__list three-rows">
                                <li v-for="(industry, index) in company.regCompanyIndustry" :key="industry.id" class="dropdown__item">
                                  <div class="custom-check dropdown__link">
                                    <label class="custom-check__label">
                                      <input class="custom-check__input"
                                        v-model="industry.selected"
                                        @change="inputChangeIndustry(index)"
                                        type="checkbox" name="chekbox">
                                      <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                          <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                        </g>
                                      </svg>
                                      <span class="custom-check__text limit_width">{{industry.value}}</span>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>


                          </div>

                          <div class="comp-prof-banner-txt" v-if="company.view_company_profile.companytype == 'business' && type == 'view'">
                            <p><strong>Field of Expertise</strong></p>
                            <ul class="comp-view-list">
                              <li v-for="industry in company.view_company_profile.companyindustry.slice(0, 4)" :key="industry.id">{{industry.value}}</li>
                              <li v-if="company.view_company_profile.companyindustry.length > 4">+{{company.view_company_profile.companyindustry.length-4}}</li>
                            </ul>
                          </div>

                          <!-- investor -->
                          <div v-if="type == 'edit'">

                          <div :class="{'dropdown_active': selectIndustry}" class="dropdown" v-if="company.companyInfo.companytype == 'investment'">
                            <div @click.prevent="openDropDown('selectIndustry')" class="dropdown__init btn btn_select">
                              <span class="title-text">
                                {{ company.regCompanyIndustry.filter(a => a.selected).length ?
                                  company.regCompanyIndustry.filter(a => a.selected).map(a => a.value).join(', ') :
                                  'Field of Expertise' }}
                                  <!-- Please select ... -->
                              </span>
                              <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                              </svg>
                            </div>
                            <div class="dropdown__content">
                              <ul class="dropdown__list three-rows">
                                <li v-for="(industry, index) in company.regCompanyIndustry" :key="industry.id" class="dropdown__item">
                                  <div class="custom-check dropdown__link">
                                    <label class="custom-check__label">
                                      <input class="custom-check__input"
                                        v-model="industry.selected"
                                        @change="inputChangeIndustry(index)"
                                        type="checkbox" name="chekbox">
                                      <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                          <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                        </g>
                                      </svg>
                                      <span class="custom-check__text limit_width">{{industry.value}}</span>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>


                          </div>

                          <div class="comp-prof-banner-txt" v-if="company.view_company_profile.companytype == 'investment' && type == 'view'">
                            <p><strong>Fields of Focus</strong></p>
                            <ul class="comp-view-list">
                              <li v-for="industry in company.view_company_profile.companyindustry.slice(0, 4)" :key="industry.id">{{industry.value}}</li>
                              <li v-if="company.view_company_profile.companyindustry.length > 4">+{{company.view_company_profile.companyindustry.length-4}}</li>
                            </ul>
                          </div>
                      </div>
                  </div><!-- //form-field -->
              </div>

              <div class="col col_3 col_big-desktop-3 col_mob-12">
                  <div class="form-field mb_0">
                      <div v-if="type == 'edit'"
                      class="form-field__content">
                        <input
                          v-model="companyLocation"
                          ref="companyLocationInput"
                          id="pac-input"
                          class="form-field__input"
                          type="text"
                          required
                          :placeholder="'Company Location'">
                      </div>
                      <div v-if="type == 'view'"
                      class="form-field__content">
                        <div class="comp-prof-banner-txt">
                          <p><strong>Company Location</strong></p>
                          <ul class="comp-view-list"><li>{{company.view_company_profile.companycity}}, {{company.view_company_profile.companycountry}}</li></ul>
                        </div>
                      </div>
                      <ToolTip class="company-address"
                        :type="'top-right'"
                        :msg="'Here we have filters to help you define your company and its position making it easier for the most relevant people on our platform to find you intuitively with ease! More filters will be added and in the meantime let us know what filters would be helpful for you that we can add (as well as options within the filters)'"
                      />
                  </div><!-- //form-field -->
              </div>

          </div>
          <CompanyBannerPopup></CompanyBannerPopup>
      </div>

  </div>
</template>

<script>
import store from '@/store'
import CompanyBannerPopup from '@/components/popups/CompanyBanner'
import ToolTip from '@/components/ToolTip'

export default {
    name: "ConfirmPopup",
    components: {
      CompanyBannerPopup,
      ToolTip,
    },
    props: {
      type: String,
    },
    data() {
      return {
        industry: '',
        typeInvestment:'',
        stageOfFunding: '',

        selectIndustry: false,
        selectToI: false,
        selectSoF: false,
      }
    },
    mounted() {
      this.getCompanyIndustry();
    },
    methods: {
      initInputAddress() {
        let self = this;
        let options = {
            types: ['(cities)']
        };
        if (this.type !== 'view') {
          const input = document.getElementById("pac-input");
          const autocomplete = new google.maps.places.Autocomplete(input, options);
          this.location = google.maps.event.addListener(autocomplete, 'place_changed', function () {
              let place = autocomplete.getPlace();
              self.selectedCity = place.address_components[0].long_name;
              for (let index = 0; index < place.address_components.length; index++) {
                  if(place.address_components[index].types[0] == "country"){
                      self.selectedCountry = place.address_components[index].long_name;
                  }
              }
          });
          setTimeout(() => {input.setAttribute('autocomplete','new-password');}, 1000);
          input.addEventListener('click', function() {
              input.setAttribute('autocomplete','new-password');
          });
        }
      },
      async getCompanyIndustry() {
        await store.dispatch('GET_MY_COMPANY_INFO');
        let industry = '';

        switch (this.company.companyInfo.companytype) {
          case 'startup':
              industry = 'entrepreneur';
            break;
          case 'business':
              industry = 'expert';
            break;
          case 'investment':
              industry = 'investor';
            break;
        }

        store.dispatch('GET_COMPANY_INDUSTRY', industry);
        this.initInputAddress();
      },
      editCompanyBanner: function() {
        store.dispatch('SHOW_POPUP', { popup: 'companyBannerPopup', show: true});
      },
      changeCompanyLike: function() {
        store.dispatch('COMPANY_LIKE', this.company.view_company_profile.id);
      },
      stop: function() {
        return '';
      },

      openDropDown(target) {
        let old = this[target];
          this.selectIndustry = false;
          this.selectToI = false;
          this.selectSoF = false;
          this[target] = !old;
      },
      closeSelect(e) {
        if (!e.target.closest(".dropdown_active")) {
          this.selectIndustry = false;
          this.selectToI = false;
          this.selectSoF = false;
        }
      },

      selectStageOfFunding(id) {
        let self = this;
        self.company.regCompanyFunding.forEach((item, index) => {
          if (item.id == id) {
            self.company.regCompanyFunding[index].selected = true;
            self.company.companyInfo.investment = item.value;
          } else {
            self.company.regCompanyFunding[index].selected = false;
          }
        });
        this.selectSoF = false;
      },

      selectTypeOfInvestments(id) {
        let self = this;
        self.company.regCompanyInvestment.forEach((item, index) => {
          if (item.id == id) {
            self.company.regCompanyInvestment[index].selected = true;
            self.company.companyInfo.investment = item.value;
          } else {
            self.company.regCompanyInvestment[index].selected = false;
          }
        });
        this.selectToI = false;
      },

      inputChangeIndustry(current) {
        if (this.company.companyInfo.companytype == 'investment') return;
        let self = this;
        let count = 0;
        let indexes = [];
        this.company.regCompanyIndustry.forEach(function(item, index) {
          if (item.selected) {
            indexes.push(index);
            count ++;
            if (count > 4) {
              if (current == indexes[indexes.length - 2])
                self.company.regCompanyIndustry[indexes[indexes.length - 3]].selected = false;
              else
                self.company.regCompanyIndustry[indexes[indexes.length - 2]].selected = false;
            }
          }
        });
      },

      sendMSG() {
          let data = {
              recipient: this.company.view_company_profile.id,
              type: 'company'
          };
          store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: true, data: data});
          return;
      },

    },

    computed: {
        companyLocation: {
          get() {
            return store.state.company.companyInfo.companycity + ", " + store.state.company.companyInfo.companycountry;
          }
        },
        company: () => store.state.company,
        popups: () => store.state.popups,
        userdata: () => store.state.user,
    },

    created() {
      document.addEventListener('click', this.closeSelect);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.closeSelect)
    }
}
</script>

<style scoped>
.section__banner {
  position: relative;
}
.banner-settings {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.btn.btn_tertiary.active{
    color: #ffffff;
    border-color: #00A961;
    background-color: #00A961;
}
.btn.btn_tertiary.active .btn__ico path{
    fill: #ffffff;
}
</style>