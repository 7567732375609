<template>
    <div :class="{ 'popup-visible' : this.popups.WhatIsManagerPopup }">
    <div class="popup-wrap">
        <!-- // successfully -->
        <div class="popup successfully-popup" :class="{ 'active' : this.popups.WhatIsManagerPopup }">
            <form action="#" class="popup__form">
                <div class="popup__header">
                    <div class="popup__title">
                        <span class="title-text">What is a Comms Manager?</span>
                    </div>
                    <div @click.prevent="closePopup" class="popup-remove">
                        <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                  fill="#333333"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                  fill="#333333"/>
                        </svg>
                    </div>
                </div><!-- //popup__header -->
                <div class="popup__content">
                    <div class="popup__section">
                        <div class="section">
                          <div class="section__header"></div>
                          <div class="section__content">
                            <p>A Communications Manager is a member or are members of the team that will recieve messages on behalf of the team. The Comms Managers will have a shared messaging box for other people on the platform to message. Any Comms Manager can access the message and reply. Upon replying the message will be moved from the shared message box to that specific Comms Manager's personal box for continued conversation.
                            <br>They will also deal with the requests to access information that your team has decided needs to be requested.
                            <br>There needs to be at least one Comms Manager per team.</p>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="popup__footer">
                    <div class="btn-group btn-group_secondary content-between">
                        <a href="#" @click.prevent="closePopup" class="">
                            
                        </a>
                        <a href="#" @click.prevent="closePopup" class="btn btn_secondary">
                            <span class="btn__text">Close</span>
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    </div>
    </template>
    
    <script>
    import store from '@/store'
    
    export default {
        name: "WhatIsManagerPopup",
        methods: {
            closePopup() {
                store.dispatch('SHOW_POPUP', { popup: 'WhatIsManagerPopup', show: false});
            }
        },
        computed: {
            popups: () => store.state.popups,
        },
    }
    </script>