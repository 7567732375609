<template>
  <!-- team -->
  <div class="section">
      <div class="section__header">
          <div class="section__wysiwyg wysiwyg">
              <h2>Team members
                  <!-- <a @click.prevent="WhatIsManager" href="#" v-if="!this.company.view_company_profile.id  || this.company.view_company_profile.id == this.userdata.user_profile.companyid">What is a Comms Manager?</a> -->
                  <ToolTip 
                    :type="'top'"
                    :msg="'This space is where you can either add or remove team members as well as make a team member a Comm’s Manager or Team Administrator. You do not need to add your investors/experts as team members to invite them to a work page (others signed up as entrepreneurs will need to be added to your team in order to bring them into one of your work pages).<br><br>All team members will be auto joined to your Home Page as well as any work page where the option to automatically join new team members is left on.<br><br>Building your team into the platform will help you manage your company’s tasks, organize your team and business, and let all who are relevant to your business know who you and your team are with a little more depth and access to know why to work with or invest into your company.'"
                  />
              </h2>
          </div>
          <div v-if="type == 'edit' && ['administrator','community_manager'].indexOf(company.companyInfo.current_user_role) === 0" class="section__action">
              <div class="btn-group btn-group_secondary">
                  <a href="#" @click.prevent="removeMembers" class="btn btn_tertiary">
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z"
                                fill="#00A961"/>
                      </svg>
                      <span class="btn__text">Remove members</span>
                  </a>
                  <a href="#" @click.prevent="inviteToteam" class="btn btn_tertiary">
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z"
                                fill="#00A961"/>
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z"
                                fill="#00A961"/>
                      </svg>
                      <span class="btn__text">Invite to team</span>
                  </a>
              </div>
          </div>
      </div>

      <div class="section__content">

          <div class="team">
              <div class="team__list">
                  <div v-for="member in this.company.companyMembers" :key='member.id' class="team__item team-item">
                      <div class="team-item__header">
                          <div  @click="activateDropdownItem(member)" :class="{ 'dropdown_active' : active_el === member.id }" class="team-item__dropdown dropdown dropdown_secondary dropdown_secondary-content">
                              <div v-if="member.id !== this.userdata.user_profile.id" class="dropdown__init">
                                  <svg class="ico" width="18" height="18"
                                        viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9Z"
                                            fill="#333333"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M12.75 9C12.75 8.17157 13.4216 7.5 14.25 7.5C15.0784 7.5 15.75 8.17157 15.75 9C15.75 9.82843 15.0784 10.5 14.25 10.5C13.4216 10.5 12.75 9.82843 12.75 9Z"
                                            fill="#333333"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M2.25 9C2.25 8.17157 2.92157 7.5 3.75 7.5C4.57843 7.5 5.25 8.17157 5.25 9C5.25 9.82843 4.57843 10.5 3.75 10.5C2.92157 10.5 2.25 9.82843 2.25 9Z"
                                            fill="#333333"/>
                                  </svg>
                              </div>
                              <div v-if="member.id !== this.userdata.user_profile.id" class="dropdown__content">
                                  <ul class="dropdown__list">
                                      <template v-if="this.company.companyInfo.current_user_role == 'administrator' && type == 'edit' && member.in_company_role !== 'owner'">
                                        <li v-if="member.in_company_role !== 'administrator'"  class="dropdown__item">
                                            <a href="#" @click.prevent="toggleCompanyRole(member.id, 'administrator')" class="dropdown__link">Add as administrator</a>
                                        </li>
                                        <li v-if="member.in_company_role === 'administrator'"  class="dropdown__item">
                                            <a href="#" @click.prevent="toggleCompanyRole(member.id, 'administrator')" class="dropdown__link">Remove as administrator</a>
                                        </li>
                                        <li v-if="member.in_company_role !== 'community_manager'"  class="dropdown__item">
                                            <a href="#" @click.prevent="toggleCompanyRole(member.id, 'community_manager')" class="dropdown__link">Add as communication manager</a>
                                        </li>
                                        <li v-if="member.in_company_role === 'community_manager'"  class="dropdown__item">
                                            <a href="#" @click.prevent="toggleCompanyRole(member.id, 'community_manager')" class="dropdown__link">Remove as communication manager</a>
                                        </li>
                                      </template>
                                      <li v-if="!member.private_message" class="dropdown__item">
                                          <a href="#" @click.prevent="contactSendMessage(member.id)" class="dropdown__link">Send
                                              Message</a>
                                      </li>
                                      <li v-if="member.in_my_crm" class="dropdown__item">
                                          <a href="#" @click.prevent="contactRemoveCrm(member)" class="dropdown__link">Remove
                                              from CRM</a>
                                      </li>
                                      <li v-if="!member.in_my_crm && !member.private && !member.private_message" class="dropdown__item" >
                                          <a href="#" @click.prevent="contactAddCrm(member)" class="dropdown__link">Add to CRM</a>
                                      </li>
                                      <li v-if="member.in_my_crm" class="dropdown__item">
                                        <router-link :to="{ name: 'crmUser', params: { id: member.crm_id } }" class="dropdown__link">Go to CRM</router-link>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div class="team-item__avatar">
                        <img v-if="member.avatar" :src="member.avatar" alt="" />
                        <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                      </div>

                      <div class="team-item__content">
                          <div class="team-item__name">
                              <router-link :to="'/profile/'+member.id" :class="{ disabled: member.private}">
                                <span class="name-text">{{ member.firstname }} {{ member.lastname }}</span>
                              </router-link>
                          </div>
                          <div class="team-item__data team-data">
                              <div class="team-data__item">
                                  <span v-if="member.position" class="team-data__text">{{ member.position }} </span>
                              </div>
                              <div class="team-data__item">
                                  <router-link :to="'/company_profile/'+member.company_id">
                                    <span class="team-data__text team-data-companyname__text">
                                      {{ member.companyname }}
                                    </span>
                                  </router-link>
                                  <span class="team-data__separator">/</span>
                                  <span class="team-data__text">{{ member.country }}</span>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

      </div>
      <WhatIsManagerPopup></WhatIsManagerPopup>
      <CompanyRemoveMembers></CompanyRemoveMembers>
      <CompanyInviteMembers></CompanyInviteMembers>
  </div>
</template>

<script>
import store from '@/store'
import CompanyRemoveMembers from '@/components/popups/CompanyRemoveMembers'
import CompanyInviteMembers from '@/components/popups/CompanyInviteMembers'
import WhatIsManagerPopup from '@/components/popups/WhatIsManager'
import ToolTip from '@/components/ToolTip'

export default {
    components: {
      CompanyRemoveMembers,
      CompanyInviteMembers,
      WhatIsManagerPopup,
      ToolTip,
    },
    props: {
      type: String,
    },
    data() {
      return {
        active_el: '',
      }
    },
    methods: {
      removeMembers: function() {
        store.commit('COMPANY_MEMBERS_REMOVE_INIT');
        store.dispatch('SHOW_POPUP', { popup: 'companyRemoveMembers', show: true});
      },
      inviteToteam() {
        if (!this.company.companyInfo.companyname) {
          store.dispatch('SHOW_POPUP', { popup: 'errorsPopup', show: true, data: [["Add Your Company name first"]] });
          return;
        }
        store.dispatch('SHOW_POPUP', { popup: 'companyInviteMember', show: true});
      },
      activateDropdownItem(member) {
        console.log(member)
        if (member.private || member.private_message) {
          this.active_el = '';
          return;
        }
        if(this.active_el && this.active_el==member.id){
          this.active_el = '';
        } else {
          this.active_el = member.id;
        }
      },
      contactSendMessage: function(id) {
        let data = {
            recipient: id,
            type: 'user'
        };
        store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: true, data: data});
        return;
      },
      async contactRemoveCrm(member) {
        await store.dispatch('REMOVE_CONTACT_FROM_CRM', { contact_id: member.crm_id });
        store.dispatch('GET_COMPANY_MEMBERS', member.company_id);
      },
      async contactAddCrm(member) {
        await store.dispatch('ADD_CONTACT_TO_CRM', { contact_id: member.id, type: 'user' });
        store.dispatch('GET_COMPANY_MEMBERS', member.company_id);
      },
      toggleCompanyRole(id, role) {
        let index = this.company.companyMembers.findIndex(x => x.id == id);
        let prev_role = this.company.companyMembers[index].in_company_role;
        role = role === prev_role ? '' : role;
        store.dispatch('SET_COMPANY_ROLE', { id: id, role: role });
        this.company.companyMembers[index].in_company_role = role;
      },
      WhatIsManager: function() {
        store.dispatch('SHOW_POPUP', { popup: 'WhatIsManagerPopup', show: true });
      }
    },
    computed: {
      company: () => store.state.company,
      popups: () => store.state.popups,
      userdata: () => store.state.user,
      crm: () => store.state.crm,
    },
}
</script>
<style>
.disabled {
  pointer-events: none;
}
</style>