<template>
<div :class="{ 'popup-visible' : this.popups.companyRemoveMembers }">
        <div class="popup-wrap" @click.self="closePopup">
            <div class="popup successfully-popup popup-remove-members" :class="{ 'active' : this.popups.companyRemoveMembers }">
            <form action="#" class="popup__form">
        <div class="popup__header">
            <div class="popup__title">
                <span class="title-text">Remove Members</span>
            </div>
            <div class="popup-remove" @click.prevent="closePopup">
                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                          fill="#333333"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                          fill="#333333"/>
                </svg>
            </div>
        </div><!-- //popup__header -->
        <div class="popup__content">
            <div class="popup__section">
                <div class="row">
                  <div class="col col_12 popup-remove-members-search">
                      <div class="form-field">
                          <div class="form-field__content">
                              <input  ref="search_users" v-model="searchuser" v-on:input="searchUserHandler" type="text" placeholder="Search users" class="form-field__input">
                          </div>
                      </div><!-- //form-field -->
                  </div>
                </div>

                <div class="row">
                  <div class="col col_12">
                      <div class="form-field">
                        <div v-if="this.company.removeSearchResult.length > 0" class="select__list">
                            <div v-for="member in this.company.removeSearchResult" :key='member.id' class="select__item select-item members-popup-list">
                                <div class="select-item__avatar">
                                    <img v-if="member.avatar" :src="member.avatar" alt="" />
                                    <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                                </div>
                                <div class="select-item__wysiwyg wysiwyg">

                                    <span class="title-text">{{member.firstname}} {{member.lastname}}</span>
                                    <ul>
                                        <li v-if="member.position" class="list__item">{{member.position}}</li>
                                        <li class="list__item">{{member.companyname}}</li>
                                        <li class="list__item">{{member.country}}, {{member.city}}</li>
                                    </ul>
                                </div>
                                <div class="custom-check">
                                  <div v-if="this.userdata.user_profile.id !== member.id">
                                    <label class="custom-check__label">
                                      <input class="custom-check__input" type="checkbox" v-model="removeList" :value="member.id" >
                                      <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                            <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                          </g>
                                      </svg>
                                    </label>
                                  </div>
                              </div>
                            </div>
                        </div>

                        <p v-else class="empty-remove">There are no users yet.</p>
                      </div>
                  </div>
                </div>


            </div>
        </div>
        <div class="popup__footer">
            <div class="btn-group btn-group_secondary content-between">
                <a href="#" @click.prevent="closePopup" class="btn btn_secondary">
                    <span class="btn__text">Cancel</span>
                </a>
                <a href="#"  @click.prevent="removeCompanyMembers" class="btn btn_primary">
                    <span class="btn__text">Remove</span>
                </a>
            </div>
        </div>
    </form>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store'

export default {
    data() {
      return {
        active_el:'',
        searchuser: '',
        removeList: [],
        loadedAvatars: false,
      }
    },
    updated() {
    },
    methods: {
        searchUserHandler: function() {
            this.loadedAvatars = false;
            if(this.searchuser.length >= 1) {
                store.dispatch('SEARCH_USER_TO_REMOVE', this.searchuser);
            } else {
                store.commit('COMPANY_MEMBERS_REMOVE_INIT');
            }
        },
      closePopup: function() {
        this.searchuser = '';
        store.commit('COMPANY_MEMBERS_REMOVE_INIT');
        this.removeList = [];
        store.dispatch('SHOW_POPUP', { popup: 'companyRemoveMembers', show: false});
      },

      async removeCompanyMembers() {
          if(this.removeList.length > 0) {
            store.dispatch('REMOVE_MEMBERS_LIST', this.removeList);
          }
      }
    },
    computed: {
      userdata:() => store.state.user,
      company: () => store.state.company,
      popups: () => store.state.popups,
    },
}
</script>
