<template>
<div :class="{ 'popup-visible' : this.popups.previewCompanyPopup }">
<div class="popup-wrap">
    <!-- // successfully -->
    <div class="popup successfully-popup" :class="{ 'active' : this.popups.previewCompanyPopup }">
        <form action="#" class="popup__form">
            <div class="popup__header">
                <div class="popup__title">
                    <span class="title-text"></span>
                </div>
                <div @click.prevent="closePopup" class="popup-remove">
                    <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                              fill="#333333"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                              fill="#333333"/>
                    </svg>
                </div>
            </div><!-- //popup__header -->
            <div class="popup__content">
                <div class="popup__section">


                    <div class="section">
                      <div class="section__header">
                        <div class="preview-company__logo">
                          <img alt="" :src="this.popups.previewCompanyData.companylogosrc">
                        </div>
                        <div class="section__wysiwyg wysiwyg">
                            <h2>{{this.popups.previewCompanyData.companyname}}</h2>
                            <ul>
                              <li v-if="this.popups.previewCompanyData" class="list__item">{{this.popups.previewCompanyData.industry.join(', ')}}</li>
                              <li class="list__item">{{this.popups.previewCompanyData.companycountry}}, {{this.popups.previewCompanyData.companycity}}</li>
                            </ul>
                        </div>
                        <div class="btn_like">
                            <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7125 1.49902C12.0721 1.49902 11.438 1.62519 10.8464 1.87033C10.2548 2.11544 9.7172 2.47482 9.26451 2.92769L8.99997 3.19223L8.7353 2.92756C7.82088 2.01314 6.58065 1.49942 5.28747 1.49942C3.99428 1.49942 2.75406 2.01314 1.83964 2.92756C0.925216 3.84198 0.411499 5.08221 0.411499 6.37539C0.411499 7.66858 0.925216 8.9088 1.83964 9.82322L8.46964 16.4532C8.76253 16.7461 9.2374 16.7461 9.5303 16.4532L16.1603 9.82322C16.6132 9.37053 16.9724 8.83306 17.2175 8.2415C17.4627 7.64989 17.5888 7.01578 17.5888 6.37539C17.5888 5.735 17.4627 5.10089 17.2175 4.50928C16.9724 3.91778 16.613 3.3801 16.1602 2.92744C15.7075 2.47463 15.1701 2.11542 14.5786 1.87033C13.987 1.62519 13.3529 1.49902 12.7125 1.49902Z" fill="#00A961"></path>
                            </svg>
                            <span class="btn__text">Like ({{this.popups.previewCompanyData.like}})</span>
                        </div>
                      </div>

                      <div class="section__content">

                        <div  class="form-field mb_0">
                          <div class="form-field__content preview-company__desc">
                            <p v-if="this.popups.previewCompanyData.description" v-html="this.highLight(this.popups.previewCompanyData.description)"></p>
                          </div>
                        </div>
                      </div>
                    </div>



                    <p>This is your company card, this is how other users on the platform will see your company card. What you fill out here is what others will see in the description box for your company card. It will also be visible on your company profile page.</p>
                </div>
            </div>
            <div class="popup__footer">
                <div class="btn-group btn-group_secondary content-between">
                    <a href="#" @click.prevent="closePopup" class="">

                    </a>
                    <a href="#" @click.prevent="closePopup" class="btn btn_secondary">
                        <span class="btn__text">Close</span>
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
</template>

<script>
import store from '@/store'
import func from '@/plugins/functions'
// import { router } from '@/router'

export default {
    name: "PreviewCompanyPopup",
    methods: {
        highLight(text) {
            return func.highLight(text);
        },
        closePopup() {
            store.dispatch('SHOW_POPUP', { popup: 'previewCompanyPopup', show: false});
        }
    },
    computed: {
        popups: () => store.state.popups,
    },
}
</script>

<style>
.btn_like{
    white-space: nowrap;
    color: #00A961;
    border-color: transparent;
    background-color: transparent;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-align: center;
    margin: 0;
    outline: none;
}
.btn_like .btn__ico{
    width: 18px;
    min-width: 18px;
    margin-right: 5px;
}
.btn_like path{
    fill: #00A961;
}
</style>