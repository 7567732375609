<template>
  <div :class="{ 'popup-visible' : this.popups.companyInviteMember }">
    <div class="popup-wrap" @click.self="closePopup">
      <div class="popup successfully-popup popup-remove-members popup-invite-members" :class="{ 'active' : this.popups.companyInviteMember }">
        
        <div class="popup__header">
          <div class="popup__title">
            <span class="title-text">Invite To Team</span>
          </div>
          <div class="popup-remove" @click.prevent="closePopup">
            <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"/>
            </svg>
          </div>
        </div><!-- //popup__header -->
        
        <div class="popup__content">
          <div class="popup__section">
            <div class="row">
              <div class="col col_12">
                <div class="tabs">
                  
                  <div class="tabs-header tabs-header-media">
                    <div @click="inviteTabClick" class="tabs-header__item tabs-header__item_active">
                      <span class="tabs-header__text">Search for users inside the Platform</span>
                    </div>
                    <div @click="inviteTabClick" class="tabs-header__item">
                      <span class="tabs-header__text">Invite to team via email</span>
                    </div>
                  </div><!-- //tabs-header -->
                  
                  <div class="tabs-body">
                    <div class="tabs-body__item tabs-body__item_media tabs-body__item_active">
                      <div class="row">
                        <div class="col col_12">
                          <div class="form-field input-user-invite">
                            <div class="form-field__content">
                              <input  ref="search_users" v-model="searchuser" v-on:input="searchUserHandler" type="text" placeholder="Search users" class="form-field__input">
                            </div>
                          </div><!-- //form-field -->
                          
                          <div class="form-field">
                            <div v-if="this.company.inviteSearchResult.length > 0" class="select__list">
                              <div v-for="member in this.company.inviteSearchResult" :key='member.id' class="select__item select-item members-popup-list">
                                <div class="select-item__avatar">
                                  <img v-if="member.avatar" :src="member.avatar" alt="" />
                                  <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                                </div>
                                
                                <div class="wysiwygs-col">
                                  <div class="select-item__wysiwyg wysiwyg">
                                    <span class="title-text">{{member.firstname}} {{member.lastname}}</span>
                                    <ul>
                                      <li v-if="member.position" class="list__item">{{member.position}}</li>
                                      <li v-if="member.company.companyname" class="list__item">{{member.company.companyname}}</li>
                                      <li class="list__item">{{member.country}}, {{member.city}}</li>
                                    </ul>
                                  </div>
                                  <div class="select-item__wysiwyg wysiwyg check-add-member">
                                    <div class="custom-check">
                                      <div v-if="this.userdata.user_profile.id !== member.id">
                                        <label class="custom-check__label">
                                          <input class="custom-check__input" type="checkbox" v-model="inviteListAdmin" :value="member.id" :disabled="member.block_add">
                                          <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                              <rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                              <g>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path>
                                              </g>
                                            </g>
                                          </svg>
                                          <span class="custom-check__text">Add as admin</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="member.block_add" class="exclude_from_invite">{{member.alert_msg}}</div>
                                </div>
                                
                                <div class="custom-check">
                                  <div v-if="this.userdata.user_profile.id !== member.id">
                                    <label class="custom-check__label">
                                      <input class="custom-check__input" type="checkbox" v-model="inviteList" :value="member.id" :disabled="member.block_add">
                                      <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                          <rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                          <g>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                            <p v-else class="empty-remove">There are no users yet.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="tabs-body__item tabs-body__item_media ">
                      <div class="form-field input-user-email-invite">
                        <div class="form-field__header">
                          <label class="form-field__title">Email</label>
                        </div>
                        <div class="form-field__content">
                          <input v-model="inviteEmail" ref="email" type="text" placeholder="Please enter email"
                          class="form-field__input">
                        </div>
                      </div><!-- //form-field -->
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="popup__footer">
          <div class="btn-group btn-group_secondary content-between">
            <a href="#" @click.prevent="closePopup" class="btn btn_secondary">
              <span class="btn__text">Cancel</span>
            </a>
            <a href="#"  @click.prevent="inviteMembers" class="btn btn_primary">
              <span class="btn__text">Invite to team</span>
            </a>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
    data() {
      return {
        active_el:'',
        searchuser: '',
        inviteEmail: '',
        inviteList: [],
        inviteListAdmin: [],
        membersExcludes:[]
      }
    },
    updated() {
        // let companyMembers = this.company.inviteSearchResult;
        // for (let index = 0; index < companyMembers.length; index++) {
        //   if((this.userdata.user_profile.account_type == "entrepreneur" || this.userdata.user_profile.account_type == "expert") && companyMembers[index].account_type == "investor") {
        //     this.membersExcludes[companyMembers[index].id] = false;
        //   } else if((this.userdata.user_profile.account_type == "investor" || this.userdata.user_profile.account_type == "expert") && companyMembers[index].account_type == "entrepreneur") {
        //     this.membersExcludes[companyMembers[index].id] = false;
        //   } else {
        //     this.membersExcludes[companyMembers[index].id] = true;
        //   }

        // }
    },
    methods: {
      inviteTabClick: function() {
        this.searchuser = '';
        this.inviteEmail = '';
        this.inviteList = [];
        this.inviteListAdmin = [];
        store.dispatch('RESET_SEARCH_USER_TO_INVITE');
      },
      closePopup: function() {
        this.searchuser = '';
        store.dispatch('RESET_SEARCH_USER_TO_INVITE');
        store.dispatch('SHOW_POPUP', { popup: 'companyInviteMember', show: false});
      },
      searchUserHandler: function() {
        if(this.searchuser.length >= 1) {
          store.dispatch('SEARCH_USER_TO_INVITE', this.searchuser);
        } else {
          store.dispatch('SEARCH_USER_TO_INVITE');
        }
      },
      inviteMembers: function() {
        let data = {};
        // Invite to email
        if(this.inviteEmail.length > 0) {
          data.company = this.company.companyInfo.id;
          data.email = this.inviteEmail;
          data.role = this.userdata.user_profile.account_type;
          this.sendInvite(data);
        }

        // Invite to list
        if(this.inviteList.length > 0) {
          data.inviteList = this.inviteList;
          data.asAdmin = this.inviteListAdmin;
          data.company = this.company.companyInfo.id;
          this.sendInvite(data);
        }
      },
      sendInvite: function(data){
        store.dispatch('SEND_INVITE', data);
        /* let popupMsg = '';
        if(this.company.inviteErrorMsg){
          console.log('11');
          popupMsg = this.company.inviteErrorMsg[0];
        } else {
          console.log('22');
          popupMsg = 'The invite was successfully sent to the user /users. Once accepted, the user will be added to your team.';
        }
        store.dispatch('SHOW_POPUP', { popup: 'successPopup', show: popupMsg }); */
        this.searchuser = '';
        this.inviteEmail = '';
        this.inviteList = [];
        this.inviteListAdmin = [];
        store.dispatch('RESET_SEARCH_USER_TO_INVITE');
        store.dispatch('SHOW_POPUP', { popup: 'companyInviteMember', show: false});
      }
    },
    computed: {
      userdata:() => store.state.user,
      company: () => store.state.company,
      popups: () => store.state.popups,
    },
}
</script>
<style>
.exclude_from_invite {
  font-size: 12px;
  color: brown;
  text-align: left;
}
</style>