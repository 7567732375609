<template>
  <!-- description -->
  <div class="section">
      <div class="section__header">
          <div class="section__wysiwyg wysiwyg">
              <h2>Description
                  <a v-if="type == 'edit'" href="#" @click.prevent="previewCompanyCard">What is this for?</a>
              </h2>
          </div>
      </div>

      <div class="section__content">

        <div v-if="type == 'edit'" class="form-field mb_0">
          <div class="form-field__content">
            <textarea v-model="description" ref="desc" name=""
              placeholder="Write as detailed as possible about your company ..."></textarea>
          </div>
        </div><!-- //form-field -->

        <div v-else class="wysiwyg">
          <p v-if="company.view_company_profile.companydescription" v-html="this.highLight(company.view_company_profile.companydescription)"></p>
        </div>
        <PreviewCompanyPopup></PreviewCompanyPopup>
      </div>

  </div>
</template>

<script>
import store from '@/store'
import func from '@/plugins/functions'
import PreviewCompanyPopup from '@/components/popups/PreviewCompany'

export default {

    components: {
      PreviewCompanyPopup
    },
    props: {
      type: String,
    },
    data() {
      return {
        companyDescription: ''
      }
    },
    mounted() {

    },
    beforeMount() {

    },
    updated() {

    },
    methods: {
      highLight(text) {
        return func.highLight(text);
      },
      previewCompanyCard: function() {
        let data = {};
        data.companylogosrc = this.company.companyInfo.companylogo?.link;
        data.companyname = this.company.companyInfo.companyname;
        let companyindustry = [];
        for (let index = 0; index < this.company.companyInfo.companyindustry.length; index++) {
          companyindustry.push(this.company.companyInfo.companyindustry[index].value);
        }
        data.industry = companyindustry;
        data.companycity = this.company.companyInfo.companycity;
        data.companycountry = this.company.companyInfo.companycountry;
        data.description = this.$refs.desc.value;
        data.like = this.company.companyInfo.like;
        console.log(data);
        store.dispatch('SHOW_POPUP', { popup: 'previewCompanyPopup', show: true, data: data });
      }
    },
    computed: {
      description: {
        get() {
          return store.state.company.companyInfo.companydescription;
        },
        set(newVal) {
          this.companyDescription = newVal;
        }
      },
      company: () => store.state.company,
    },
}
</script>